import Swiper, { Navigation, Pagination } from 'swiper';

// スライダー
new Swiper('.swiper', {
  modules: [Navigation, Pagination],
  loop: true,
  autoplay: {
    delay: 5000,
  },
  centeredSlides: true,
  slidesPerView: 1.35,
  spaceBetween: 12,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  breakpoints: {
    // when window width is >= 745px
    745: {
      slidesPerView: 2.6,
      spaceBetween: 20,
    },
  },
});
